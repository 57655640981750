<template>
  <div>
    <source-log-search class="pt-5"></source-log-search>
    <v-row>
      <v-col class="outline--text mt-7" style="max-width: 700px">
        <div>
          <span v-if="!isLoading && this.totalCount > 0">
            <p v-if="this.totalCount >= this.currentPage * this.itemsPerPage">
              Showing {{ (this.currentPage - 1) * this.itemsPerPage + 1 }}-{{
                this.currentPage * this.itemsPerPage
              }}
              of {{ this.totalCount }} items
            </p>
            <p v-else>
              Showing {{ (this.currentPage - 1) * this.itemsPerPage + 1 }}-{{
                this.totalCount
              }}
              of {{ this.totalCount }} items
            </p>
          </span>
        </div>
      </v-col>
      <v-col>
        <div class="d-flex justify-space-between">
          <v-spacer></v-spacer>
          <v-pagination
            v-if="showPages && pageCount > 1"
            class="my-3"
            v-model="pagination.page"
            :length="pageCount"
            :total-visible="7"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
          ></v-pagination>
          <div class="d-flex mx-10">
            <v-select
              v-model="pagination.rowPerPage"
              item-text="label"
              item-value="attribute"
              :items="countPerPage"
              label="Show items"
              style="max-width: 80px"
            >
            </v-select>
          </div>
          <div class="d-flex">
            <v-select
              item-text="label"
              item-value="attribute"
              :items="sortAttributes"
              label="sortBy"
              v-model="sort.sortBy"
              style="max-width: 180px"
            ></v-select>
            <span v-if="sort.sortBy" class="mt-5 ml-2">
              <v-icon
                v-if="sort.desc"
                title="sortAsc"
                @click="sort.desc = false"
              >
                mdi-sort-descending
              </v-icon>
              <v-icon v-else title="sortDesc" @click="sort.desc = true">
                mdi-sort-ascending
              </v-icon>
            </span>
            <v-btn
              depressed
              rounded
              small
              outlined
              icon
              color="grey darken-1"
              style="float: right"
              class="my-5 mx-6"
              @click="printItems"
              ><v-icon> mdi-printer-outline </v-icon></v-btn
            >
            <v-btn
              depressed
              rounded
              small
              outlined
              icon
              color="grey darken-1"
              style="float: right"
              class="my-5"
              @click="exportItems"
              ><v-icon> mdi-file-export-outline </v-icon></v-btn
            >
          </div>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      id="itemsTable"
      :headers="headers"
      :loading="isLoading"
      :items="logs"
      :items-per-page="5"
      disable-pagination
      disable-filtering
      disable-sort
      hide-default-footer
      :item-class="itemClass"
    >
      <template v-slot:item.channelLink="{ item }">
        <a target="_blank" :href="item.channelLink">
          {{ item.channelLink }}
        </a>
      </template>
      <template v-slot:item.parsingStatus="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }" class="pa-0">
            <span v-bind="attrs" v-on="on">
              <img
                v-if="item.parsingStatus === 'error'"
                class="mx-8"
                :src="require('@/assets/alert-box-outline.png')"
                alt=""
              />
              <img
                v-else-if="item.parsingStatus === 'warning'"
                class="mx-8"
                :src="require('@/assets/alert-outline.png')"
                alt=""
              />
              <img
                v-else
                class="mx-8"
                :src="require('@/assets/check-circle-outline.png')"
                alt=""
              />
            </span>
          </template>
          <p v-if="item.parsingStatus === 'warning'" class="my-2">Warning</p>
          <p v-else-if="item.parsingStatus === 'error'" class="my-2">Error</p>
          <p v-else class="my-2">Success</p>
        </v-tooltip>
      </template>
      <template v-slot:item.code="{ item }">
        <span v-if="item.parsingStatus === 'warning'" class="orange--text">
          {{ item.code }}
        </span>
        <span v-else-if="item.parsingStatus === 'error'" class="red--text">
          {{ item.code }}
        </span>
        <span v-else class="green--text">
          {{ item.code }}
        </span>
        <p class="grey--text">{{ item.description }}</p>
      </template>
      <template v-slot:item.proxyUsing="{ item }">
        <span v-if="item.proxyUsing">Yes</span>
        <span v-else>No</span>
      </template>
      <template v-slot:item.parsingDate="{ item }">
        <span class="grey--text">{{ item.parsingDate }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import _ from "lodash";
import BaseListPage from "../../base/BaseListPage";
import SourceLogSearch from "./SourceLogSearch";
import { mapGetters, mapState } from "vuex";
import { FETCH_CHANNELS } from "../../../store/modules/channel/actions";
import {
  defaultFilters,
  emptyFilters,
  multipleFilters,
} from "@/store/modules/logs/state";
import {
  FETCH_SOURCE_LOG,
  FETCH_SOURCE_LOG_FILE,
  SET_FILTERS,
} from "@/store/modules/logs/actions";

export default {
  name: "SourceLogList",
  props: {
    sourceId: {
      required: true,
    },
  },
  extends: BaseListPage,
  components: {
    SourceLogSearch,
  },
  data() {
    return {
      defaultFilters,
      emptyFilters,
      multipleFilters,
      showPages: false,
      countPerPage: [
        {
          attribute: 20,
          label: "20",
        },
        {
          attribute: 50,
          label: "50",
        },
        {
          attribute: 100,
          label: "100",
        },
        {
          attribute: 5000,
          label: "All",
        },
      ],
      headers: [
        {
          text: "Channel id",
          value: "channelId",
          width: "125",
        },
        {
          text: "Channel name",
          value: "channelName",
          width: "200",
        },
        {
          text: "Channel link",
          value: "channelLink",
          width: "150",
          align: "center",
        },
        {
          text: "Parsing date",
          value: "parsingDate",
          width: "200",
          align: "center",
        },
        {
          text: "Parsing type",
          value: "parsingType",
          width: "200",
          align: "center",
        },
        {
          text: "Parsing status",
          value: "parsingStatus",
          width: "50",
          align: "center",
        },
        {
          text: "Error code",
          value: "code",
          width: "175",
          align: "center",
        },
        {
          text: "Network error",
          value: "error",
          width: "200",
          align: "center",
        },
        {
          text: "Use proxy",
          value: "proxyUsing",
          width: "50",
        },
        {
          text: "Proxy address",
          value: "proxyAddress",
          width: "125",
        },
      ],
      sortAttributes: [
        {
          attribute: "channelName",
          label: "Channel name",
        },
        {
          attribute: "parsingDate",
          label: "Parsing date",
        },
        {
          attribute: "proxyUsing",
          label: "Use proxy",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("log", [
      "logList",
      "totalCount",
      "pageCount",
      "currentPage",
      "offset",
      "formattedFilters",
    ]),
    ...mapState("log", ["sort", "pagination", "filters"]),
    itemsPerPage: function () {
      return this.pagination.rowPerPage;
    },
    logs: function () {
      if (this.isLoading) {
        return [];
      }
      return this.logList;
    },
  },
  watch: {
    "pagination.rowPerPage": function () {
      this.pagination.page = 1;
    },
  },
  methods: {
    applyFilters: function (payload) {
      return this.$store.dispatch(`log/${SET_FILTERS}`, payload.data);
    },
    fetchItems: function () {
      const vm = this;
      vm.isLoading = true;
      const { sourceId } = this;
      vm.$store.dispatch(`log/${FETCH_SOURCE_LOG}`, { sourceId }).then(
        () => {
          vm.isLoading = false;
          vm.showPages = true;
        },
        (error) => {
          console.log(error);
        }
      );
      this.$store.dispatch(`channels/${FETCH_CHANNELS}`, { sourceId });
    },
    exportItems: function () {
      const vm = this;
      const { sourceId } = vm;
      vm.$store.dispatch(`log/${FETCH_SOURCE_LOG_FILE}`, { sourceId }).then(
        () => {},
        (error) => {
          console.log(error);
        }
      );
    },
    printItems: function () {
      this.$htmlToPaper("itemsTable");
    },
  },
  mounted() {
    const vm = this;
    vm.applyFilters({
      data: _.merge({}, vm.defaultFilters),
    });
    vm.pagination.page = 1;
    this.applyRouteParams();
    this.fetchItems();
  },
};
</script>
