<template>
  <div>
    <div>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-actions class="py-0">
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDelete">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
          <v-card-title
            >Are you sure you want to delete this item?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click="closeDelete">Cancel</v-btn>
            <v-btn color="primary" text @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div>
      <v-dialog v-model="editDialog" max-width="500px">
        <v-card>
          <v-form @submit.prevent="putEditItem" ref="channelForm">
            <v-row no-gutters class="my-0 py-0">
              <v-spacer></v-spacer
              ><v-card-actions>
                <v-btn icon @click="clearEditDialog">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-actions>
            </v-row>
            <v-card-title>
              <v-row class="mx-2">Edit {{ editedItem.name }}</v-row>
            </v-card-title>
            <v-card-text>
              <v-alert text color="red" type="error" v-if="errorMsg">
                {{ errorMsg }}
              </v-alert>
              <v-container>
                <v-row>
                  <v-col cols="10" sm="5" md="5">
                    <v-text-field
                      v-model="editedItem.name"
                      required
                      :rules="[inputRequired, minCharsRule, maxCharsRule]"
                      label="Channel name"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="editedItem.url"
                      required
                      :rules="[inputRequired, urlRule]"
                      label="Channel URL"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      v-model="editedItem.type"
                      chips
                      deletable-chips
                      small-chips
                      required
                      item-value="value"
                      item-text="label"
                      :rules="[inputRequired]"
                      :items="channelTypes"
                      label="Type"
                      value="Website"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      v-model="editedItem.isActive"
                      item-value="value"
                      item-text="label"
                      :items="[
                        { label: 'No', value: 0 },
                        { label: 'Yes', value: 1 },
                      ]"
                      :rules="[booleanRequired]"
                      :disabled="editedItem.isCustom"
                      label="Enabled"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="5">
                    <v-select
                      v-model="editedItem.proxyUsing"
                      item-value="value"
                      item-text="label"
                      :items="[
                        { label: 'No', value: 0 },
                        { label: 'Yes', value: 1 },
                      ]"
                      :rules="[booleanRequired]"
                      :disabled="editedItem.isSocial"
                      label="Proxy using"
                      required
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row v-if="editedItem.proxyUsing">
                  <v-col cols="12" sm="12" md="12">
                    <v-select
                      v-model="editedItem.proxyPackageId"
                      item-value="id"
                      item-text="name"
                      :items="proxyPackageList"
                      :rules="[inputRequired]"
                      label="Proxy package"
                      required
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                      v-model="editedItem.parserConfig"
                      outlined
                      name="inputParserConfig"
                      label="Parser config"
                      hint="Parser config in JSON format (Not required)"
                      rows="3"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey darken-1" text @click="clearEditDialog">
                Cancel
              </v-btn>
              <v-btn color="primary" text @click="putEditItem"> Save </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>

      <v-dialog
        v-if="selectedChannel"
        v-model="showHistoryDialog"
        max-width="700px"
      >
        <v-card>
          <v-card-title
            class="headline primary lighten-1 white--text justify-space-between"
          >
            Show {{ selectedChannel.name }} parsing history
            <v-btn
              icon
              @click="
                selectedChannel = null;
                showHistoryDialog = false;
              "
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="historyHeaders"
              :items="channelParsingHistory"
              :items-per-page="5"
              class="elevation-0"
            >
              <template v-slot:item.parsingRate="{ item }">
                <span>{{ item.parsingRate }}%</span>
              </template>
              <template v-slot:item.period="{ item }">
                <span>{{ item.periodStart }} - {{ item.periodEnd }}</span>
              </template>
              <template v-slot:item.total="{ item }">
                <span class="red--text">{{ item.totalErrors }}</span>
                /<span class="green--text">{{ item.totalParsed }}</span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <h1>
      <channel-add-dialog
        v-if="this.$route.name === 'SourceItemPage' && canManage"
        :sourceId="sourceId"
      ></channel-add-dialog>
    </h1>
    <channel-search class="pt-5"></channel-search>
    <v-row>
      <v-col class="outline--text mt-7" style="max-width: 700px">
        <div>
          <span v-if="!isLoading && this.totalCount > 0">
            <p v-if="this.totalCount >= this.currentPage * this.itemsPerPage">
              Showing {{ (this.currentPage - 1) * this.itemsPerPage + 1 }}-{{
                this.currentPage * this.itemsPerPage
              }}
              of {{ this.totalCount }} items
            </p>
            <p v-else>
              Showing {{ (this.currentPage - 1) * this.itemsPerPage + 1 }}-{{
                this.totalCount
              }}
              of {{ this.totalCount }} items
            </p>
          </span>
        </div>
      </v-col>
      <v-col>
        <div class="d-flex justify-space-between">
          <v-spacer></v-spacer>
          <v-pagination
            v-if="showPages && pageCount > 1"
            class="my-3"
            v-model="pagination.page"
            :length="pageCount"
            :total-visible="7"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
          ></v-pagination>
          <div class="d-flex mx-10">
            <v-select
              v-model="pagination.rowPerPage"
              item-text="label"
              item-value="attribute"
              :items="countPerPage"
              label="Show items"
              style="max-width: 80px"
            >
            </v-select>
          </div>
          <div class="d-flex">
            <v-select
              item-text="label"
              item-value="attribute"
              :items="sortAttributes"
              label="sortBy"
              v-model="sort.sortBy"
              style="max-width: 180px"
            ></v-select>
            <span v-if="sort.sortBy" class="mt-5 ml-2">
              <v-icon
                v-if="sort.desc"
                title="sortAsc"
                @click="sort.desc = false"
              >
                mdi-sort-descending
              </v-icon>
              <v-icon v-else title="sortDesc" @click="sort.desc = true">
                mdi-sort-ascending
              </v-icon>
            </span>
            <v-btn
              depressed
              rounded
              small
              outlined
              icon
              color="grey darken-1"
              style="float: right"
              class="my-5 mx-6"
              @click="printItems"
              ><v-icon> mdi-printer-outline </v-icon></v-btn
            >
            <v-btn
              depressed
              rounded
              small
              outlined
              icon
              color="grey darken-1"
              style="float: right"
              class="my-5"
              @click="exportItems"
              ><v-icon> mdi-file-export-outline </v-icon></v-btn
            >
          </div>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      id="itemsTable"
      :headers="headers"
      :loading="isLoading"
      :items="channels"
      :items-per-page="5"
      disable-pagination
      disable-filtering
      disable-sort
      hide-default-footer
      class="mx-0"
      :item-class="itemClass"
    >
      <template v-slot:item.NextRun="{ item }">
        <v-tooltip
          v-if="!item.isSocial"
          bottom
          :disabled="!item.isActive || !sourceItemAlias(item).isActive"
        >
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-icon
                class="mx-0 mb-1"
                style="cursor: pointer; color: dodgerblue"
                :disabled="!item.isActive || !sourceItemAlias(item).isActive"
                @click="startParsingNow(item, $event.target)"
                >mdi-arrow-right-drop-circle
              </v-icon>
            </span> </template
          >Start Now</v-tooltip
        >
      </template>
      <template v-slot:item.isActive="{ item }">
        <v-switch
          v-if="!item.isCustom"
          class="pa-0 px-0 mx-0"
          dense
          color="#67bb6a"
          v-model="item.isActive"
          :label="item.id.toString()"
          @click="editStatus(item)"
          :disabled="!canManage"
        >
        </v-switch>
        <v-switch
          v-else
          class="pa-0 px-0 mx-0"
          dense
          color="#67bb6a"
          v-model="item.isActive"
          disabled
        >
          <template v-slot:label>
            <span class="black--text">{{ item.id.toString() }}</span>
          </template>
        </v-switch>
      </template>
      <template v-slot:item.name="{ item }">
        <img :src="item.img" alt="" class="mr-2 pa-0" />
        <a target="_blank" :href="item.url" class="black--text">
          {{ item.name }}
        </a>
      </template>
      <template v-slot:item.type="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <img
                v-if="item.type === 'rss'"
                :src="require('@/assets/rss.png')"
                alt=""
              />
              <img
                v-else-if="item.type === 'facebook'"
                :src="require('@/assets/facebook.png')"
                alt=""
              />
              <img
                v-else-if="item.type === 'youtube'"
                :src="require('@/assets/youtube.png')"
                alt=""
              />
              <img
                v-else-if="item.type === 'twitter'"
                :src="require('@/assets/twitter.png')"
                alt=""
              />
              <img v-else :src="require('@/assets/web.png')" alt="" /> </span
          ></template>
          <p v-if="item.type === 'website'" class="my-2">Website</p>
          <p v-else-if="item.type === 'subsite'" class="my-2">Subsite</p>
          <p v-else-if="item.type === 'rss'" class="my-2">RSS</p>
          <p v-else-if="item.type === 'facebook'" class="my-2">Facebook</p>
          <p v-else-if="item.type === 'youtube'" class="my-2">YouTube</p>
          <p v-else-if="item.type === 'twitter'" class="my-2">Twitter</p>
          <p v-else class="my-2">Website</p>
        </v-tooltip>
      </template>
      <template v-slot:item.lastErrorCode="{ item }">
        <span v-if="item.isSocial" class="grey--text">NA</span>
        <span
          v-else-if="
            1000 < parseInt(item.lastErrorCode) &&
            parseInt(item.lastErrorCode) < 1300
          "
          class="orange--text"
        >
          {{ item.lastErrorCode }}
          <span class="grey--text">{{ item.lastErrorDescription }}</span>
        </span>
        <span
          v-else-if="1300 <= parseInt(item.lastErrorCode)"
          class="red--text"
        >
          {{ item.lastErrorCode }}
          <span class="grey--text">{{ item.lastErrorDescription }}</span>
        </span>
        <span v-else class="green--text">
          {{ item.lastErrorDescription }}
        </span>
      </template>
      <template v-slot:item.parsingStatus="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <img
                v-if="!item.isActive"
                class="mx-8"
                :src="require('@/assets/broadcast-off.png')"
                alt=""
              />
              <img
                v-else-if="item.sourceParsingStatus === -1"
                class="mx-8"
                :src="require('@/assets/alert-box-outline.png')"
                alt=""
              />
              <img
                v-else-if="item.sourceParsingStatus === 0"
                class="mx-8"
                :src="require('@/assets/alert-outline.png')"
                alt=""
              />
              <img
                v-else
                class="mx-8"
                :src="require('@/assets/check-circle-outline.png')"
                alt=""
              />
            </span>
          </template>
          <p v-if="item.sourceParsingStatus === 0" class="my-2">Warning</p>
          <p v-else-if="item.sourceParsingStatus === -1" class="my-2">Error</p>
          <p v-else class="my-2">Success</p>
        </v-tooltip>
      </template>
      <template v-slot:item.proxyUsing="{ item }">
        <p v-if="item.proxyUsing" class="mt-3">Yes</p>
        <p v-else class="mt-3">No</p>
      </template>
      <template v-slot:item.formattedSourceParsingStatus="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="d-flex align-center">
              <img
                v-if="!item.isActive"
                :src="require('@/assets/broadcast-off.png')"
                alt=""
              />
              <span v-else-if="item.isSocial"></span>
              <img
                v-else-if="item.formattedSourceParsingStatus === 'error'"
                :src="require('@/assets/alert-box-outline.png')"
                alt=""
              />
              <img
                v-else-if="item.formattedSourceParsingStatus === 'warning'"
                :src="require('@/assets/alert-outline.png')"
                alt=""
              />
              <img
                v-else
                :src="require('@/assets/check-circle-outline.png')"
                alt=""
              />
              <span v-if="!item.isActive"> Off </span>
              <span v-else-if="item.isSocial" class="grey--text">NA</span>
              <span
                v-else-if="item.formattedSourceParsingStatus === 'warning'"
                class="orange--text ml-1"
              >
                Warning
              </span>
              <span
                v-else-if="item.formattedSourceParsingStatus === 'error'"
                class="red--text ml-1"
              >
                Error
              </span>
              <span v-else class="green--text ml-1"> Success </span>
              <span class="red--text ml-1 mr-1"> {{ item.numErrors }}</span>
              <span class="ml-1"> / {{ item.numTries }} </span>
            </div>
          </template>
          Tries quantity: {{ item.numTries }}<br />
          Errors quantity: {{ item.numErrors }}
        </v-tooltip>
      </template>
      <template v-slot:item.articleParsingRate="{ item }">
        <span v-if="item.isSocial" class="mx-10 grey--text">NA</span>
        <span v-else>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-if="item.isActive" v-bind="attrs" v-on="on">
                {{ item.articleParsingRate }}
              </span>
              <span v-else v-bind="attrs" v-on="on"> 0% </span>
            </template>
            <p class="my-2">Success rate</p>
          </v-tooltip>
          =<span class="red--text">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-if="item.isActive" v-bind="attrs" v-on="on">
                  {{ item.totalArticleParsingError }}</span
                >
                <span v-else v-bind="attrs" v-on="on"> 0</span>
              </template>
              <p class="my-2">Unsuccessful tasks</p>
            </v-tooltip></span
          >/<span class="green--text"
            ><v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-if="item.isActive" v-bind="attrs" v-on="on">{{
                  item.totalArticleParsingSuccess
                }}</span>
                <span v-else v-bind="attrs" v-on="on">0</span>
              </template>
              <p class="my-2">Successful tasks</p>
            </v-tooltip></span
          >
        </span>
        <v-btn
          depressed
          rounded
          x-small
          icon
          color="grey darken-1"
          class="ml-2"
          @click="fetchParsingHistory(item)"
        >
          <v-icon> mdi-eye </v-icon>
        </v-btn>
      </template>
      <template v-slot:item.Copy="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-icon
                class="mx-0 mb-1"
                small
                style="cursor: pointer"
                @click="copyName(item)"
                >mdi-content-copy
              </v-icon>
            </span> </template
          >Copy URL</v-tooltip
        >
      </template>
      <template v-slot:item.Actions="{ item }">
        <v-icon v-if="canManage" class="mr-2" @click="editItem(item)">
          mdi-pencil-outline
        </v-icon>
        <v-icon v-if="canManage" color="red" @click="deleteItem(item)">
          mdi-delete-forever-outline
        </v-icon>
      </template>
      <template v-slot:item.lastSourceParsing="{ item }">
        <span v-if="item.isSocial" class="grey--text">NA</span>
        <span v-else class="grey--text">{{ item.lastSourceParsing }}</span>
      </template>
      <template v-slot:item.nextSourceParsing="{ item }">
        <span v-if="item.isSocial" class="grey--text">NA</span>
        <span v-else class="grey--text">{{ item.nextSourceParsing }}</span>
      </template>
      <template v-slot:item.lastArticleParsing="{ item }">
        <span v-if="item.isSocial" class="grey--text">NA</span>
        <span v-else class="grey--text">{{ item.lastArticleParsing }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapState } from "vuex";
import BaseListPage from "../../base/BaseListPage";
import ChannelSearch from "./ChannelSearch";
import ChannelAddDialog from "../item/ChannelAddDialog";
import { DELETE_CHANNEL } from "@/store/modules/channel/actions";
import { NEW_CREATED_CHANNEL } from "@/utils/events";
import { EventBus } from "@/utils";
import { FETCH_CHANNELS } from "@/store/modules/channel/actions";
import {
  FETCH_CHANNEL,
  UPDATE_CHANNEL,
  FETCH_CHANNEL_PARSING_HISTORY,
} from "@/store/modules/channel/actions";
import { SET_FILTERS } from "@/store/modules/channel/actions";
import { FETCH_CHANNELS_FILE } from "@/store/modules/channel/actions";
import {
  defaultFilters,
  emptyFilters,
  multipleFilters,
} from "@/store/modules/channel/state";
import acl from "@/mixin/acl";
import { RESOURCE_CHANNELS_MANAGEMENT } from "@/store/modules/admin/getters";
import { FETCH_PROXY_PACKAGES } from "@/store/modules/proxyPackage/actions";

export default {
  name: "ChannelList",
  mixins: [acl],
  props: {
    sourceId: {
      required: true,
    },
  },
  extends: BaseListPage,
  components: {
    ChannelSearch,
    ChannelAddDialog,
  },
  data() {
    return {
      defaultFilters,
      emptyFilters,
      multipleFilters,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      dialogDelete: false,
      showPages: false,
      countPerPage: [
        {
          attribute: 20,
          label: "20",
        },
        {
          attribute: 50,
          label: "50",
        },
        {
          attribute: 100,
          label: "100",
        },
        {
          attribute: 5000,
          label: "All",
        },
      ],
      channelTypes: [
        {
          label: "Website",
          value: "website",
        },
        {
          label: "Subsite",
          value: "subsite",
        },
        {
          label: "RSS",
          value: "rss",
        },
        {
          label: "Facebook",
          value: "facebook",
        },
        {
          label: "YouTube",
          value: "youtube",
        },
        {
          label: "Twitter",
          value: "twitter",
        },
      ],
      headers: [
        {
          text: "",
          value: "NextRun",
          width: "10",
        },
        {
          text: "",
          value: "isActive",
          width: "75",
        },
        {
          text: "Name",
          value: "name",
          width: "250",
        },
        {
          text: "",
          value: "Copy",
          width: "10",
        },
        {
          text: "Type",
          value: "type",
          width: "10",
        },
        {
          text: "Proxy Using",
          value: "proxyUsing",
          width: "25",
          align: "center",
        },
        {
          text: "Last source parsing",
          value: "lastSourceParsing",
          width: "185",
          align: "center",
        },
        {
          text: "Next source parsing",
          value: "nextSourceParsing",
          width: "185",
          align: "center",
        },
        {
          text: "Source parsing",
          value: "formattedSourceParsingStatus",
          width: "195",
          align: "center",
        },
        {
          text: "Last article parsing",
          value: "lastArticleParsing",
          width: "185",
          align: "center",
        },
        {
          text: "Article parsing",
          value: "articleParsingRate",
          width: "180",
        },
        {
          text: "Last error code",
          value: "lastErrorCode",
          width: "135",
          align: "center",
        },
        {
          text: "Created",
          value: "createdAt",
          width: "185",
        },
        {
          text: "",
          value: "Actions",
          width: "90",
        },
      ],
      historyHeaders: [
        {
          text: "Period",
          value: "period",
        },
        {
          text: "Total article parsing",
          value: "total",
        },
        {
          text: "Total rate",
          value: "parsingRate",
        },
      ],
      sortAttributes: [
        {
          attribute: "id",
          label: "ID",
        },
        {
          attribute: "name",
          label: "Name",
        },
        {
          attribute: "isActive",
          label: "Enabled",
        },
        {
          attribute: "articleParsingRate",
          label: "Article Parsing",
        },
        {
          attribute: "sourceParsingStatus",
          label: "Source Parsing",
        },
      ],
      errorMsg: "",
      inputRequired: (v) => !!v || "Required field",
      booleanRequired: (v) => !!v || v !== false || v !== 0 || "Required field",
      minCharsRule: (v) => (v && v.length >= 3) || "Input too short!",
      maxCharsRule: (v) => (v && v.length <= 40) || "Input too long!",
      urlRule: (v) =>
        new RegExp(
          "^(https?:\\/\\/)?" +
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
            "((\\d{1,3}\\.){3}\\d{1,3}))" +
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*",
          "i"
        ).test(v) || "Incorrect URL",
      requiredRule: (v) => !!v || "Not enough characters to save the channel",
      selectedChannel: null,
      showHistoryDialog: false,
      channelParsingHistory: [],
    };
  },
  computed: {
    ...mapGetters("channels", [
      "channelList",
      "totalCount",
      "currentPage",
      "pageCount",
      "offset",
      "formattedFilters",
    ]),
    ...mapGetters("proxyPackage", ["proxyPackageList"]),
    ...mapState("channels", ["sort", "pagination", "filters"]),
    ...mapGetters("source", ["sourceItem"]),
    ...mapGetters("customSource", ["customSourceItem"]),
    sourceItemAlias() {
      return function (item) {
        if (item.isCustom) {
          return this.customSourceItem;
        } else {
          return this.sourceItem;
        }
      };
    },
    canManage: function () {
      return this.hasAccess([RESOURCE_CHANNELS_MANAGEMENT]);
    },
    itemsPerPage: function () {
      return this.pagination.rowPerPage;
    },
    channels: function () {
      if (this.isLoading) {
        return [];
      }
      return this.channelList;
    },
  },
  watch: {
    channelList: function () {},
    "pagination.rowPerPage": function () {
      this.pagination.page = 1;
    },
  },
  methods: {
    copyName(item) {
      navigator.clipboard.writeText(item.url);
    },
    clearEditDialog() {
      this.errorMsg = "";
      this.closeEditDialog();
    },
    applyFilters: function (payload) {
      return this.$store.dispatch(`channels/${SET_FILTERS}`, payload.data);
    },
    fetchItems: function () {
      const vm = this;
      vm.isLoading = true;
      const { sourceId } = this;
      vm.$store.dispatch(`proxyPackage/${FETCH_PROXY_PACKAGES}`);
      vm.$store.dispatch(`channels/${FETCH_CHANNELS}`, { sourceId }).then(
        () => {
          vm.isLoading = false;
          vm.showPages = true;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    fetchItem: function (itemId, how) {
      const vm = this;
      vm.$store.dispatch(`channels/${FETCH_CHANNEL}`, { itemId, how }).then(
        () => {},
        (error) => {
          console.log(error);
        }
      );
    },
    editItem: function (item) {
      this.editedItem = _.merge({}, item);
      this.editDialog = true;
    },
    editStatus(item) {
      this.editedItem = _.merge({}, item);
      this.putEditItem();
    },
    startParsingNow(item, target) {
      let vm = this;
      this.editedItem = _.merge({}, item);
      this.editedItem.moveNextRun = true;
      this.putEditItem();
      this.editedItem.moveNextRun = false;
      let times = [500, 1000];
      times.forEach(function (e) {
        setTimeout(() => {
          vm.toggleVisibility(target.parentElement.parentElement.parentElement);
        }, e); // Set the timeout duration for blinking
      });
    },
    toggleVisibility(item) {
      if (item.classList.contains("animate__slideInRight")) {
        item.classList.remove("animate__slideInRight");
      } else {
        item.classList.add("animate__slideInRight");
      }
    },
    putEditItem() {
      if (this.editDialog) {
        if (!this.$refs.channelForm.validate()) {
          this.errorMsg = "Please check all fields!";
          return;
        }
      }
      const vm = this;
      let { editedItem } = this;
      let { id } = editedItem;
      vm.$store
        .dispatch(`channels/${UPDATE_CHANNEL}`, {
          id,
          editedItem,
        })
        .then(
          () => {
            vm.fetchItem(this.editedItem.id);
            if (this.editDialog) {
              this.clearEditDialog();
            }
          },
          (error) => {
            this.errorMsg = error;
          }
        );
    },
    fetchParsingHistory(item) {
      this.selectedChannel = item;
      this.$store
        .dispatch(`channels/${FETCH_CHANNEL_PARSING_HISTORY}`, {
          id: this.selectedChannel.id,
        })
        .then(
          (data) => {
            this.showHistoryDialog = true;
            this.channelParsingHistory = data.data;
          },
          (error) => {
            this.errorMsg = error;
          }
        );
    },
    closeDelete() {
      this.dialogDelete = false;
      this.selectedItem = null;
    },
    deleteItem(item) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },
    deleteItemConfirm: function () {
      let vm = this;
      vm.$store
        .dispatch(`channels/${DELETE_CHANNEL}`, vm.selectedItem.id)
        .then(
          function () {
            vm.fetchItems();
          },
          function (error) {
            vm.errorMsg = error;
          }
        )
        .catch(() => {
          vm.errorMsg = "Something went wrong";
        });
      this.dialogDelete = false;
    },
    exportItems: function () {
      const vm = this;
      const { sourceId } = vm;
      vm.$store.dispatch(`channels/${FETCH_CHANNELS_FILE}`, { sourceId }).then(
        () => {},
        (error) => {
          console.log(error);
        }
      );
    },
    printItems: function () {
      this.$htmlToPaper("itemsTable");
    },
  },
  mounted() {
    const vm = this;
    vm.applyFilters({
      data: _.merge({}, vm.defaultFilters),
    });
    vm.pagination.page = 1;
    vm.applyRouteParams();
    vm.fetchItems();
  },
  created() {
    EventBus.$on(NEW_CREATED_CHANNEL, (sourceId, channelId) => {
      this.sourceId = sourceId;
      this.fetchItem(channelId, "push");
    });
  },
};
</script>
