<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        depressed
        rounded
        color="primary"
        style="float: right"
        v-bind="attrs"
        v-on="on"
        >Add channel</v-btn
      >
    </template>
    <v-card>
      <v-form @submit.prevent="saveChannel" ref="channelForm">
        <v-row no-gutters class="my-0 py-0">
          <v-spacer></v-spacer
          ><v-card-actions>
            <v-btn icon @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
        </v-row>
        <v-card-title>
          <v-row class="mx-2">Add channel</v-row>
        </v-card-title>
        <v-card-text>
          <v-alert text color="red" type="error" v-if="errorMsg">
            {{ errorMsg }}
          </v-alert>
          <v-container>
            <v-row>
              <v-col cols="10" sm="5" md="5">
                <v-text-field
                  v-model="newItem.name"
                  required
                  :rules="[inputRequired, minCharsRule, maxCharsRule]"
                  label="Channel name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="newItem.url"
                  required
                  :rules="[inputRequired, urlRule]"
                  label="Channel URL"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="newItem.type"
                  chips
                  deletable-chips
                  small-chips
                  required
                  item-value="key"
                  item-text="label"
                  :rules="[inputRequired]"
                  :items="['Website', 'RSS', 'Facebook', 'YouTube', 'Twitter']"
                  label="Type"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-select
                  v-model="newItem.isActive"
                  item-value="attribute"
                  item-text="label"
                  :items="boolItems"
                  :rules="[inputRequired]"
                  label="Enabled"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="5">
                <v-select
                  v-model="newItem.proxyUsing"
                  item-value="attribute"
                  item-text="label"
                  :items="boolItems"
                  :rules="[inputRequired]"
                  label="Proxy using"
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="newItem.proxyUsing">
              <v-col cols="12" sm="12" md="12">
                <v-select
                  v-model="newItem.proxyPackageId"
                  item-value="id"
                  item-text="name"
                  :items="proxyPackageList"
                  :rules="[inputRequired]"
                  label="Proxy package"
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="newItem.parserConfig"
                  outlined
                  name="inputParserConfig"
                  label="Parser config"
                  hint="Parser config in JSON format (Not required)"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="close"> Cancel </v-btn>
          <v-btn color="primary" text @click="saveChannel"> Save </v-btn>
          <v-btn color="primary" text @click="saveChannelGo"> Save & Go </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import { EventBus } from "@/utils";
import { NEW_CREATED_CHANNEL } from "@/utils/events";
import { CREATE_CHANNEL } from "@/store/modules/channel/actions";
import {mapGetters} from "vuex";
import {FETCH_PROXY_PACKAGES} from "@/store/modules/proxyPackage/actions";

export default {
  name: "ChannelAddDialog",
  props: {
    sourceId: {
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      newItem: {
        name: null,
        url: null,
        type: null,
        isActive: null,
        proxyUsing: null,
        proxyPackageId: null,
        parserConfig: "{}",
      },
      defaultItem: {
        name: null,
        url: null,
        type: null,
        isActive: null,
        proxyUsing: null,
        proxyPackageId: null,
        parserConfig: "{}",
      },
      boolItems: [
        {
          attribute: 1,
          label: "Yes",
        },
        {
          attribute: 0,
          label: "No",
        },
      ],
      errorMsg: "",
      inputRequired: (v) => !!v || v === 0 || "Required field",
      minCharsRule: (v) => (v && v.length >= 3) || "Input too short!",
      maxCharsRule: (v) => (v && v.length <= 40) || "Input too long!",
      urlRule: (v) =>
        new RegExp(
          "^(https?:\\/\\/)?" +
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
            "((\\d{1,3}\\.){3}\\d{1,3}))" +
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*",
          "i"
        ).test(v) || "Incorrect URL",
      requiredRule: (v) => !!v || "Not enough characters to save the channel",
    };
  },
  computed: {
    ...mapGetters("proxyPackage", ["proxyPackageList"]),
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.errorMsg = null;
      this.$refs.channelForm.resetValidation();
      this.newItem = Object.assign({}, this.defaultItem);
    },
    saveChannelGo() {
      let vm = this;
      return this.createChannel(function (data) {
        vm.errorMsg = null;
        vm.$refs.channelForm.resetValidation();
        vm.newItem = Object.assign({}, vm.defaultItem);
        EventBus.$emit(NEW_CREATED_CHANNEL, vm.sourceId, data.id);
      });
    },
    saveChannel() {
      let vm = this;
      return this.createChannel(function (data) {
        vm.close();
        EventBus.$emit(NEW_CREATED_CHANNEL, vm.sourceId, data.id);
      });
    },
    createChannel(resolve) {
      let vm = this;
      if (!vm.$refs.channelForm.validate()) {
        this.errorMsg = "Please check all fields!";
      } else {
        let payload = _.merge({ sourceId: this.sourceId }, this.newItem);
        this.$store
          .dispatch(`channels/${CREATE_CHANNEL}`, payload)
          .then(resolve, function (error) {
            vm.errorMsg = error;
          })
          .catch(() => {
            this.errorMsg = "Something went wrong";
          });
      }
    },
  },
  mounted() {
    let vm = this;
    vm.$store.dispatch(`proxyPackage/${FETCH_PROXY_PACKAGES}`);
  },
};
</script>
