<template>
  <div>
    <source-item :prevRoute="prevRoute" :id="id"></source-item>
    <v-tabs v-if="canSeeChannels" v-model="tab">
      <v-tab :to="{ name: 'SourceItemPage' }">Channels List</v-tab>
      <v-tab :to="{ name: 'SourceItemLogs' }">Channel logs</v-tab>
    </v-tabs>
    <component
      v-if="canSeeChannels"
      :is="nestedPage"
      :sourceId="id"
    ></component>
  </div>
</template>

<script>
import SourceItem from "./source/item/SourceItem";
import ChannelList from "./channel/list/ChannelList";
import SourceLogList from "./sourceLog/list/SourceLogList";
import { FETCH_TAGS } from "@/store/modules/tag/actions";
import { SET_SOURCE_ID } from "@/store/modules/channel/actions";

import acl from "@/mixin/acl";
import { RESOURCE_CHANNELS } from "@/store/modules/admin/getters";
export default {
  name: "SourcePage",
  mixins: [acl],
  props: {
    id: {
      required: true,
    },
  },
  components: {
    SourceItem,
    ChannelList,
    SourceLogList,
  },
  data() {
    return {
      prevRoute: null,
      tab: null,
      items: { sourceItem: SourceItem },
    };
  },
  computed: {
    nestedPage: function () {
      return this.$route.meta.nestedComponent;
    },
    canSeeChannels: function () {
      return this.hasAccess([RESOURCE_CHANNELS]);
    },
  },
  beforeMount() {
    this.$store.dispatch(`channels/${SET_SOURCE_ID}`, this.id);
    this.$store.dispatch(`tag/${FETCH_TAGS}`);
  },
  beforeRouteEnter(to, from, next) {
    if (
      from.name === "SourceListPage" ||
      from.name === "CustomSourceListPage"
    ) {
      next((vm) => {
        vm.prevRoute = from;
      });
    } else {
      next();
    }
  },
};
</script>
